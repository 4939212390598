import { graphql } from 'gatsby'
import React from 'react'

import SEO from '@/components/seo'
import ConnectForestryBody from '@/helpers/connectForestryBody'

interface Props {
  data: GatsbyTypes.PageTemplateQuery
}

type Body = ReadonlyArray<
  Pick<GatsbyTypes.MarkdownRemarkFrontmatterBody, 'template'> &
    GatsbyTypes.HeroFragment &
    GatsbyTypes.HeroSecondaryVariantFragment &
    GatsbyTypes.FeaturedPostsFragment &
    GatsbyTypes.PartnersSectionFragment &
    GatsbyTypes.TestimonialsSliderFragment &
    GatsbyTypes.CoreValuesSectionFragment &
    GatsbyTypes.TeamSectionFragment &
    GatsbyTypes.WeAreFocusedOnSectionFragment &
    GatsbyTypes.CaseStudiesHighlightsFragment &
    GatsbyTypes.ContactFormFragment &
    GatsbyTypes.RateCardFormFragment &
    GatsbyTypes.ArticleBoxesSectionFragment &
    GatsbyTypes.CarrersBenefitSectionFragment &
    GatsbyTypes.ServiceBoxesSectionFragment &
    GatsbyTypes.ListOfPostsFragment &
    GatsbyTypes.HowWeWorkSectionFragment &
    GatsbyTypes.OurRatesSectionFragment &
    GatsbyTypes.ContactUsSectionFragment &
    GatsbyTypes.JobOffersSectionFragment &
    GatsbyTypes.MessageSentSectionFragment
>

const Page: React.FC<Props> = ({ data }) => {
  const { page, jobs } = data || {}
  function renderComponents(components: Body) {
    if (components.length) {
      return components.map((component, i) => {
        if (component.template === 'section-job-offers') return <ConnectForestryBody key={i} {...component} {...jobs} />

        return <ConnectForestryBody key={i} {...component} />
      })
    }

    return <h2>This Page doesn't have any sections in Forestry</h2>
  }

  return page && page.frontmatter.body ? (
    <>
      <SEO title={page.frontmatter.meta_tags.title} meta={page.frontmatter.meta_tags} />
      {renderComponents(page.frontmatter.body)}
    </>
  ) : (
    <h2>Problem with body of Forestry page</h2>
  )
}

export default Page

export const pageQuery = graphql`
  query PageTemplateQuery($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        url
        name
        meta_tags {
          title
          description
          og_image {
            childImageSharp {
              gatsbyImageData(width: 1500, formats: [PNG], placeholder: BLURRED, layout: FIXED)
            }
            relativePath
          }
          nofollow
          noindex
        }
        body {
          template
          ... on MarkdownRemarkFrontmatterBody {
            ...Hero
            ...HeroSecondaryVariant
            ...FeaturedPosts
            ...PartnersSection
            ...TestimonialsSlider
            ...CoreValuesSection
            ...TeamSection
            ...WeAreFocusedOnSection
            ...CaseStudiesHighlights
            ...NewsletterForm
            ...RateCardForm
            ...ArticleBoxesSection
            ...CarrersBenefitSection
            ...ServiceBoxesSection
            ...ListOfPosts
            ...HowWeWorkSection
            ...OurRatesSection
            ...ContactUsSection
            ...JobOffersSection
            ...MessageSentSection
          }
        }
      }
    }
    jobs: allGreenhouseJob {
      ...GreenhouseJobs
    }
  }
`
